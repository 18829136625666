// src/components/EndPage.js
import React from 'react';

function EndPage() {
  return (
    <div className="container end-page">
      <h1>You have completed the quiz!</h1>
      <p>Thank you for participating.</p>
    </div>
  );
}

export default EndPage;
